<template>
  <div class="d-flex flex-column align-items-center col-12">
    <div class="navbar-container">
      <div v-if="windowWidth >= 500">
        <DesktopNavBar/>
      </div>
      <div v-if="windowWidth < 500">
        <MobileNavBar/>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center dashboard-container col-12 mb-3">
      <button @click="logoutClicked()" v-if="windowWidth >= 500" class="logout-button">Logout</button>
      <div class="d-flex flex-column text-center header-text">Approved Leave Calendar</div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import MobileNavBar from "@/views/components/mobile/MobileNavBar";
import DesktopNavBar from "@/views/components/desktop/DesktopNavBar";
import {mapMutations} from "vuex";
import DashboardService from "@/services/dashboardService";
import moment from "moment";

const windowWidth = ref(window.innerWidth)
const handleResize = () => {
  windowWidth.value = window.innerWidth
}

export default {
  name: "LeaveCalendar",
  ...mapMutations(['setUserData']),
  components: {MobileNavBar, DesktopNavBar},
  data() {
    return {
      windowWidth: windowWidth,
      approvedLeaveTable: [],
      currentMonthLeaveTable: [],
      currentMonth: undefined,
      currentYear: undefined,
    }
  },
  methods: {
    logoutClicked() {
      this.setUserData(undefined);
      this.routeToPage('/login');
    },
    async getEmployeeLeaveData() {
      const response = await DashboardService.getAllEmployeeRequestedLeave();
      this.filterApprovedLeave(response);
    },
    filterApprovedLeave(leaveData) {
      this.approvedLeaveTable = leaveData.filter(obj => obj.Approved === true);
      this.filterObjectsByMonth(this.approvedLeaveTable);
    },
    filterObjectsByMonth(data) {
      this.currentMonthLeaveTable = data.filter(obj => {
        const startDate = new Date(obj.StartDate);
        const month = startDate.getMonth() + 1;
        const year = startDate.getFullYear();
        return month === this.currentMonth && year === this.currentYear;
      });
    },
    getCurrentDates() {
      this.currentMonth = moment().month() + 1;
      this.currentYear = moment().year();
    }
  },
  mounted() {
    window.addEventListener('resize', handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', handleResize);
  },
  async beforeMount() {
    this.getCurrentDates();
    await this.getEmployeeLeaveData();
  },
}
</script>

<style scoped>
.dashboard-container {
  position: relative;
  top: 0;
  padding-top: 15px;
  height: 92vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100vw;
}

.logout-button {
  position: absolute;
  background-color: #EE5C36;
  color: #FFFFFF;
  font-size: 13px;
  right: 10px;
  top: 10px;
  border: 0;
  height: 36px;
  width: 100px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dashboard-container::-webkit-scrollbar {
  display: none;
}

.header-text {
  font-size: 60px;
  color: #EE5C36;
  line-height: 1.1;
}

@media screen and (max-width: 500px ) {
  .header-text {
    font-size: 40px;
  }
}
</style>
